import styled from "@emotion/styled"
import { useTranslation } from "react-i18next";
import { styles } from "../../styles";
import { CardStyledContainersList } from "../../components/containers/CardStyledContainersList";
import Button from '../../components/Button';
import { RemovalDateChooser } from './components/RemovalDateChooser';
import { Controller, useForm } from "react-hook-form";
import { useCallback, useEffect } from "react";
import { Container } from "../../types/Container";
import { EmptyingPriceDisplay } from "./components/EmptyingPriceDisplay";
import { EmptyingSchedulingMode, EmptyingsSchedule } from "./EmptyingSchedulingPage";
import { SeriesFormArea } from "./components/SeriesFormArea";
import { useLocation } from "react-router-dom";

const WelcomeSection = styled.section`
    p {
        font-size: 14px;;
        color: ${styles.textColorSecondary700};
        max-width: 620px;
    }
`;

const ContainerSection = styled.section`
    margin-bottom: 16px;
`;

const EmptyingDateSection = styled.section`
    h2 {
        margin-top: 56px;
        margin-bottom: 24px;
        color:  ${styles.textColorSecondary700};
    
        @media (max-width: 480px) {
            margin-top: 48px;
        }
    }
`;

const BottomSection = styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 424px;
    margin-top: 64px;

    button {
        width: 156px;
        margin-bottom: 0;
    }

    @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-end;
        margin-top: 48px;
        margin-bottom: 48px;

        button {
            width: 100%;
            margin-top: 12px;
        }
    }
`;

type DataGatheringViewFormData = {
    containers: Container[];
    singleRemovalDate?: Date;
    plannedSchedules?: EmptyingsSchedule[];
    price?: number;
}

const getFormDefaultValues = (defaultValue: EmptyingsSchedule[] | undefined): DataGatheringViewFormData => {
    return {
        containers: defaultValue && defaultValue.length > 0 ? [defaultValue[0].container]: [],
        plannedSchedules: defaultValue ? defaultValue: []
    }
}

type DataGatheringViewProps = {
    mode: EmptyingSchedulingMode;
    defaultValue: EmptyingsSchedule[] | undefined;
    onUpdate: (schedules: EmptyingsSchedule[]) => void;
    onCompleted: (schedules: EmptyingsSchedule[], autoSubmitted?: boolean) => void;
}

type RouterStateParams = {
    preselectedContainer?: Container;
    preselectedRemovalDate?: Date;
    triggeredByOneClickEmptying?: boolean;
}

let updateFromOutSide = false;

export const DataGatheringView = ({ mode, defaultValue, onUpdate, onCompleted }: DataGatheringViewProps ) => {
    const { t } = useTranslation();
    const {state} = useLocation();
    let {preselectedContainer, preselectedRemovalDate, triggeredByOneClickEmptying } = (state || {}) as RouterStateParams;
    preselectedContainer = !preselectedContainer && defaultValue && defaultValue.length > 0 ? defaultValue[0].container: preselectedContainer;
    preselectedContainer = preselectedContainer && preselectedContainer.removalArea && preselectedContainer.removalArea.weekdays.length > 0 ? preselectedContainer: undefined; 

    preselectedRemovalDate = !preselectedRemovalDate && defaultValue && defaultValue.length > 0 ? defaultValue[0].emptyings[0].date: preselectedRemovalDate;

    const { handleSubmit, watch, control, formState: { errors } } = useForm({
        defaultValues: getFormDefaultValues(defaultValue)
    });

    const emptyingPrice = watch('price');

    const onSubmit = useCallback(async (formData: DataGatheringViewFormData) => {
        if (mode === 'single' && formData.singleRemovalDate && formData.containers.length > 0 && formData.price) {
            onCompleted([{container: formData.containers[0], emptyings: [{date: formData.singleRemovalDate, price: formData.price}]}])
        } 
        else if (mode === 'multi' && formData.plannedSchedules && formData.plannedSchedules.length > 0) {
            onCompleted(formData.plannedSchedules.filter(p => p.emptyings.length > 0));
        } else {
            console.log("unexpected form data", formData);
        }
    }, [mode, onCompleted]);

    const containers = watch('containers');
    const singleRemovalDate = watch('singleRemovalDate');
    const price = watch('price');
    const plannedSchedules = watch('plannedSchedules');

    useEffect(() => {
        if (updateFromOutSide) {
            updateFromOutSide = false;
            return;
        }
        updateFromOutSide = true;
        if (mode === 'single' && singleRemovalDate && containers.length > 0 && price) {
            onUpdate([{container: containers[0], emptyings: [{date: singleRemovalDate, price: price}]}])
        } 
        else if (mode === 'multi' && plannedSchedules && plannedSchedules.length > 0) {
            onUpdate(plannedSchedules.filter(p => p.emptyings.length > 0));
        } 
    })

    useEffect(() => {
        if (triggeredByOneClickEmptying) {
            if (mode === 'single' && singleRemovalDate && containers.length > 0 && price) {
                onCompleted([{container: containers[0], emptyings: [{date: singleRemovalDate, price: price}]}], true)
            } 
        }
    }, [mode, onCompleted, triggeredByOneClickEmptying, singleRemovalDate, containers, price]);

    return (
        <form>
            { mode === 'single' &&  
                <WelcomeSection>
                    <h1>{t(triggeredByOneClickEmptying ? "pages.emptying.oneclick.header": "pages.emptying.onetime.header")}</h1>
                    <p>{t(triggeredByOneClickEmptying ? "pages.emptying.oneclick.subheader": "pages.emptying.onetime.subheader")}</p>
                </WelcomeSection>
            }
            { mode === 'multi' &&  
                <WelcomeSection>
                    <h1>{t("pages.emptying.series.header")}</h1>
                    <p>{t("pages.emptying.series.subheader")}</p>
                </WelcomeSection>
            }

            <ContainerSection>
                <Controller
                    control={control}
                    name="containers"
                    rules={{ required: { value: true, message: 'Required Value' } }}
                    render={({ field: { onChange } }) => (
                        <CardStyledContainersList
                            cardWidth={200} selectionMode={'single'}
                            defaultSelection={preselectedContainer ? [preselectedContainer.id]: []}
                            onChange={onChange} />
                    )} />
                {errors.containers && <span>{errors.containers.message}</span>}
            </ContainerSection>

            { mode === 'single' &&
                <EmptyingDateSection>
                    <h2>{t("pages.emptying.onetime.emptyingDateSection.header")}</h2>
                    <Controller
                        control={control}
                        name="singleRemovalDate"
                        rules={{ required: { value: true, message: 'Required Value' } }}
                        render={({ field: { onChange } }) => (
                            <RemovalDateChooser singleRemovalDateChooser dateFormat="short" limitToSchedulableDates={true} autoSelectFirstSchedulableDate={!!triggeredByOneClickEmptying} defaultValue={preselectedRemovalDate} container={ containers && containers.length > 0 ? containers[0] : (preselectedContainer ? preselectedContainer: undefined)} onChange={onChange} />
                        )} />
                    {errors.singleRemovalDate && <span>{errors.singleRemovalDate.message}</span>}
                </EmptyingDateSection>
            }

            { mode === 'multi' &&
                <>
                <Controller
                    control={control}
                    name="plannedSchedules"
                    rules={{ required: { value: true, message: 'Required Value' } }}
                    render={({ field: { onChange, value } }) => (
                        <SeriesFormArea selectedContainer={containers && containers.length > 0 ? containers[0]: undefined} defaultValue={value} onChange={onChange}/>
                    )} />
                    {errors.plannedSchedules && <span>{errors.plannedSchedules.message}</span>}
                    </>
            }

            <BottomSection>
                <p>
                <Controller
                    control={control}
                    name="price"
                    rules={{ required: { value: true, message: 'Required Value' } }}
                    render={({ field: { onChange } }) => (
                        <EmptyingPriceDisplay emptyingData={singleRemovalDate || plannedSchedules} onChange={onChange} />
                    )} />
                </p>
                <Button onClick={handleSubmit(onSubmit)} disabled={!emptyingPrice || containers.length === 0 || (mode === 'single' && !singleRemovalDate) || (mode === 'multi' && plannedSchedules?.length === 0) }>
                    {t("buttons.next")}
                </Button>
            </BottomSection>
        </form>
    );
};

