import { useTranslation } from "react-i18next"
import { useEmptyingPricesQuery } from "../../../data/prices";
import { useCallback, useEffect } from "react";
import { EmptyingsSchedule } from "../EmptyingSchedulingPage";

type EmptyingPriceDisplayProps = {
    emptyingData: Date | EmptyingsSchedule[] | undefined;
    onChange: (price: number | undefined) => void;
}
export const EmptyingPriceDisplay = ({emptyingData, onChange}: EmptyingPriceDisplayProps) => {
    const priceConfigsQuery = useEmptyingPricesQuery();
    const { t } = useTranslation();

    const calcPrice = useCallback(() => {
        const isMulti = (emptyingData instanceof Date === false && emptyingData);
        const plannedSchedules = emptyingData as EmptyingsSchedule[];
        let price: number | undefined = undefined;
        if (isMulti && plannedSchedules && plannedSchedules.length > 0) {
            price = plannedSchedules.map(r => r.emptyings.length > 0 ? r.emptyings.map(e => e.price).reduce((prev, next) => (prev || 0) + next): 0).reduce((prev, next) => (prev || 0) + next);
        }
        if (!isMulti) {
            const date = emptyingData as Date;
            const priceConfig = (priceConfigsQuery.data || []).find(cfg => cfg.endDate.getTime() >= date.getTime() && cfg.startDate.getTime() <= date.getTime())
            price = priceConfig?.price;
        }
        return price;
    }, [emptyingData, priceConfigsQuery.data]);

    useEffect(() => {
        onChange(calcPrice());
    }, [onChange, calcPrice]);

    const price = calcPrice();
    return (
        <>
        {t("pages.emptying.onetime.emptyingDateSection.costs")}
            {price && <>&nbsp; {price}.- {t("pages.emptying.onetime.emptyingDateSection.currency")}</> }
            {!price && <>&nbsp;  -</>}
        </>
    )
}